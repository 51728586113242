import React, { useState } from "react"
import { useTheme } from "emotion-theming"
import { css } from "@emotion/core"
import { mq } from "../../layouts/theme"
import { buttonLinkStyle } from "../../components/button-link"
import { Spinner } from "../../components/spinner"

const inputStyle = css`
  padding: 8px 10px;
  font-family: ProximaNova-Semibold;
  border: none;
  outline: none;
  :disabled {
    background: white;
    opacity: 0.8;
    color: grey;
  }
`
const Input = ({ state, ...rest }) => {
  const { formValues, submissionState } = state
  const { name } = { ...rest }
  return (
    <input
      css={inputStyle}
      disabled={submissionState === "Loading"}
      value={formValues[name]}
      {...rest}
    />
  )
}

const successState = {
  submissionState: "Success",
  formValues: {
    name: "",
    email: "",
    title: "",
    comment: "",
  },
}
const failState = oldState => ({
  ...oldState,
  submissionState: "Fail",
})
const loadingState = oldState => ({
  ...oldState,
  submissionState: "Loading",
})

export const ContactUs = () => {
  const {
    colors: {
      txt: { mediumGrey },
      bg: { lightGrey },
    },
  } = useTheme()

  const [state, setState] = useState({
    submissionState: "Init", // "Init" | "Success" | "Fail"
    formValues: {
      name: "",
      email: "",
      title: "",
      comment: "",
    },
  })

  function onInputChange(event) {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    setState(state => ({
      ...state,
      formValues: {
        ...state.formValues,
        [name]: value,
      },
    }))
  }

  function onSubmit(e) {
    e.preventDefault()
    setState(loadingState)
    fetch("/.netlify/functions/contact-us", {
      method: "POST",
      body: JSON.stringify(state.formValues),
    })
      .then(res => {
        console.log(res.status)
        switch (res.status) {
          case 200:
            setState(successState)
            break
          default:
            setState(failState)
            break
        }
      })
      .catch(err => {
        console.log(err)
        setState(failState)
      })
  }
  return (
    <section
      css={css`
        display: flex;
        flex-direction: column;
        background: ${lightGrey};
        padding: 40px;
        flex: 1;
      `}
    >
      {state.submissionState === "Success" ? (
        <div
          css={css`
            margin: 20px 0px 80px 0px;
          `}
        >
          <h2
            css={css`
              font-family: ProximaNova-Bold;
              letter-spacing: 5px;
              text-align: center;
              margin-bottom: 8px;
              font-size: 36px;
            `}
          >
            THANK YOU
          </h2>
          <h6
            css={css`
              font-family: ProximaNova-Semibold;
              letter-spacing: 1.5px;
              text-align: center;
              margin-bottom: 5px;
            `}
          >
            We will get back to you shortly!
          </h6>
        </div>
      ) : (
        <>
          <div
            css={css`
              margin-bottom: 30px;
            `}
          >
            <h2
              css={css`
                font-family: ProximaNova-ExtraBold;
                letter-spacing: 2px;
                text-align: center;
                margin-bottom: 5px;
              `}
            >
              LEAVE US A MESSAGE
            </h2>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                width: 100%;
                justify-content: center;
                align-items: center;
                ${mq.mobile} {
                  flex-direction: row;
                }
              `}
            >
              <p
                css={css`
                  margin-bottom: 0px;
                  letter-spacing: 1px;
                  ${mq.mobile} {
                    margin-bottom: unset;
                  }
                `}
              >
                Interested in learning more?
              </p>
              <p
                css={css`
                  margin-bottom: 0px;
                  letter-spacing: 1px;
                  ${mq.mobile} {
                    margin-left: 3px;
                  }
                `}
              >
                Get in touch with us!
              </p>
            </div>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              margin: 0 auto;
              ${mq.mobile} {
                flex-direction: row-reverse;
                justify-content: space-between;
              }
            `}
          >
            <form
              css={css`
                display: flex;
                flex-direction: column;
                ${mq.mobile} {
                  flex-basis: 54%;
                }
              `}
              onSubmit={onSubmit}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  ${mq.tablet} {
                    flex-direction: row;
                    justify-content: space-between;
                  }
                `}
              >
                <Input
                  css={css`
                    ${mq.tablet} {
                      flex-basis: 48%;
                    }
                  `}
                  name="name"
                  state={state}
                  type="text"
                  placeholder="Your Name"
                  required
                  onChange={onInputChange}
                />
                <br
                  css={css`
                    ${mq.tablet} {
                      display: none;
                    }
                  `}
                />
                <Input
                  css={css`
                    ${mq.tablet} {
                      flex-basis: 48%;
                    }
                  `}
                  type="email"
                  name="email"
                  state={state}
                  placeholder="Your Email"
                  required
                  onChange={onInputChange}
                />
              </div>
              <br />
              <Input
                type="text"
                name="title"
                onChange={onInputChange}
                placeholder="Your Title"
                required
                state={state}
              />
              <br />
              <textarea
                placeholder="Your Comment"
                css={inputStyle}
                name="comment"
                required
                rows={7}
                disabled={state.submissionState === "Loading"}
                onChange={onInputChange}
                value={state.formValues.comment}
              />
              <br />
              <div
                css={css`
                  display: flex;
                  justify-content: flex-end;
                  ${mq.mobile} {
                    justify-content: flex-start;
                  }
                `}
              >
                <button
                  css={css`
                    ${buttonLinkStyle(css)}
                    display: flex;
                    align-items: center;
                    padding: 15px 20px;
                  `}
                  type="submit"
                  value="SEND MESSAGE"
                  disabled={state.submissionState === "Loading"}
                >
                  {state.submissionState === "Loading" ? <Spinner /> : null}
                  <span>SEND MESSAGE</span>
                </button>
              </div>
            </form>
            <div
              css={css`
                ${mq.mobile} {
                  flex-basis: 42%;
                }
              `}
            >
              <h3
                css={css`
                  font-family: ProximaNova-ExtraBold;
                  letter-spacing: 1px;
                  font-size: 16px;
                  ${mq.mobile} {
                    font-size: 26px;
                    margin-bottom: 20px;
                  }
                `}
              >
                Contact Info:
              </h3>
              {[
                {
                  key: "Address",
                  val:
                    "Unit 506-507 West Tower, Philippine Stock Exchange Centre, Exchange Road, Ortigas Center, Pasig City, Philippines",
                },
                {
                  key: "Phone",
                  val: "(+632) 9706-1562",
                },
                {
                  key: "E-mail",
                  val: "hello@fsvrc.com",
                },
                {
                  key: "Fax",
                  val: "(+632) 8706-1530",
                },
              ].map(({ key, val }) => {
                return (
                  <p
                    css={css`
                      margin-bottom: 3px;
                      ${mq.mobile} {
                        margin-bottom: 10px;
                      }
                    `}
                    key={key}
                  >
                    <b>{key}: </b>{" "}
                    <span
                      css={css`
                        color: ${mediumGrey};
                      `}
                    >
                      {val}
                    </span>
                  </p>
                )
              })}
            </div>
          </div>
        </>
      )}
    </section>
  )
}
