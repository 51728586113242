import React from "react"
import { ContactUs } from "../page-components/contact/contact-us"
import SEO from "../components/seo"

const Contact = () => {
  return (
    <>
      <SEO title="Contact Us" />
      <ContactUs />
    </>
  )
}

export default Contact
