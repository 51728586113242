import React from "react"
import { ClassNames } from "@emotion/core"
import { Link } from "gatsby"
import { css } from "@emotion/core"

export const buttonLinkStyle = css_ => css_`
  background: black;
  color: white;
  box-sizing: border-box;
  padding: 14px 33px;
  font-family: ProximaNova-Bold;
  text-align: center;
  outline: none;
  border: none;
  :disabled {
    opacity: 0.6;
  }
`

export const ButtonLink = ({ to, label, link }) => {
  return link ? (
    <a href={link} css={buttonLinkStyle(css)}>
      {label}
    </a>
  ) : (
    <ClassNames>
      {({ css }) => (
        <Link className={buttonLinkStyle(css)} to={to}>
          {label}
        </Link>
      )}
    </ClassNames>
  )
}
